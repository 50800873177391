import React, { createContext, useContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Answer, Screen } from '../templates/pages';

export interface GlobalState {
  currentScreen: Screen;
  setCurrentScreen: (currentScreen: Screen) => void;
  answers: Array<Answer>;
  setAnswers: (answers: Array<Answer>) => void;
  resetState(): void;
}

// We assume this GlobalStateContext will never be used unless inside
// the GlobalStateContext.Provider, and so the default is never used.
export const GlobalStateContext = createContext<GlobalState>({} as GlobalState);

export const useGlobalState = () => useContext(GlobalStateContext);

interface StateProviderProps {
  children: React.ReactNode;
}

export function GlobalStateProvider({ children }: StateProviderProps) {
  const [currentScreen, setCurrentScreen] = useState<Screen>('home');
  const [answers, setAnswers] = useState<Array<Answer>>([]);

  const resetState = () => {
    setCurrentScreen('home');
    setAnswers([]);
  };

  const globalState: GlobalState = {
    currentScreen,
    setCurrentScreen,
    answers,
    setAnswers,
    resetState,
  };

  return <GlobalStateContext.Provider value={globalState}>{children}</GlobalStateContext.Provider>;
}
